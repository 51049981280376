import styled, { css } from "styled-components";
import { colors, invasiveColors } from "./helpers";

/*
 * SET UP MEDIA TEMPLATES
 */
const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
};

// Iterate through the sizes and create a media template
// (taken from styled components documentation)
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

/*
 * COMPONENTS BELOW
 * ORGANIZED ALPHABETICALLY
 */

export const Card = styled.div`
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  background: white;
  display: flex;
  overflow: auto;
  height: auto;
  background: white;
  color: ${colors["black"]};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  height: 90vh;
  width: 100%;
  margin: 0 auto;
  background: #eee;
  ${media.tablet`display: block;`}
`;

export const DengueMapLegend = styled.div`
  display: flex;
  box-sizing: border-box;
  div {
    display: flex;
    margin: 0 auto;
    width: 25%;
    text-align: center;
    padding: 5px;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: white;
    height: 40px;
  }
  div:first-child {
    background: ${colors["vectorsurv-navy"]};
  }
  div:nth-child(2) {
    background: ${colors["light-blue"]};
  }
  div:nth-child(3) {
    background: ${colors["light-red"]};
  }
  div:nth-child(4) {
    background: ${colors["red"]};
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  font-family: "Roboto", sans-serif;

  select {
    color: ${colors["vectorsurv-navy"]};
    background: white;
    border: 1px solid gray;
    border-radius: 0px;
    margin: 3px 0;
    font-size: 18px;
  }
`;

export const LegendInvasive = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
  div {
    display: flex;
    justify-content: space-around;
    width: auto;
    border-radius: 5px;
    text-align: center;
    padding: 3px 6px;
  }
`;

export const MapContainer = styled.div`
  height: 90vh;
  border-radius: 25px;
  width: 100%;
  margin-top: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);

  ${media.tablet`
    margin: 0 0 0 20px;
    height: 50vh;
  `}
`;

export const MapControlsContainer = styled(Card)`
  width: 100%;
  margin: 0 0 20px 0;
  height: 50px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Navbar = styled.nav`
  background: ${colors["vectorsurv-navy"]};
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  ${media.phone`font-size: 12px;`}

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    ${media.phone`
      white-space: nowrap;
      overflow: scroll;
    `}
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-left: -12px;

    li {
      list-style: none;
    }

    li:not(:first-child) {
      margin-left: 15px;
      font-size: 17.5px;
      :hover {
        color: ${colors["vectorsurv-orange"]};
      }
    }

    .navbar--active {
      padding-bottom: 5px;
      border-bottom: 2px solid white;
    }
  }

  .button-container {
    display: flex;
    align-items: center;
  }

  /* share button */
  div.share-button {
    ${media.phone`display: none`}
    display: flex;
    align-items: center;
    padding-right: 20px;
    cursor: pointer;
    :hover {
      color: ${colors["vectorsurv-orange"]};
    }
  }

  /* extra styles on reactstrap dropdown */
  .dropdown-container {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 20px;
    ${media.phone`padding-right: 0px`}
  }

  .dropdown-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: ${colors["vectorsurv-navy"]};
    color: white;
  }

  .dropdown-item {
    color: white;
    &:hover {
      background-color: ${colors["vectorsurv-navy"]};
      color: ${colors["vectorsurv-orange"]};
    }
  }

  .dropdown-toggle {
    background-color: ${colors["vectorsurv-navy"]};
    border-color: ${colors["vectorsurv-navy"]};
    &:hover,
    &:focus,
    &:active {
      background-color: ${colors["vectorsurv-navy"]};
      border-color: ${colors["vectorsurv-navy"]};
      color: ${colors["vectorsurv-orange"]};
      box-shadow: none;
    }
    &:hover {
      color: ${colors["vectorsurv-orange"]};
    }
  }
`;

export const RangeSliderContainer = styled(Card)`
  width: 100%;
  margin: 20px 0 20px 0;
  height: 50px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* width of the actual slider between the two date values */
  div:nth-child(2) {
    width: 50%;
  }
`;

export const RegionDetails = styled(Card)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .largeDetails {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 30px;
    padding-top: 20px;
    text-align: center;

    .temp {
      div:first-child {
        color: ${colors["light-blue"]};
      }
      div {
        color: ${colors["dark-blue"]};
      }
    }
  }

  .smallDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 20px;

    .detailContainer {
      width: 90px;
      text-align: center;

      div:first-child {
        /* the value */
        font-size: 24px;
        color: ${colors["light-blue"]};
      }
      div:nth-child(2) {
        /* the label */
        font-size: 12px;
        color: ${colors["dark-blue"]};
      }
    }
  }
`;

export const RiskChartContainer = styled(Card)`
  padding: 20px;
  ${media.phone`
    padding: 0px;
  `}
  justify-content: center;
  align-items: center;
  display: block;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.37);
  margin-bottom: 20px;
`;

export const RiskDropdownContainer = styled.div`
  display: inline-block;
  margin: 0;
  text-align: center;
`;

export const ScrollBox = styled.div`
  height: 100vh;
  overflow-y: scroll;
`;

export const SelectorArbo = styled.table`
  margin: 0 auto;
  text-align: center;
  padding: 5px 0 5px 0;
  td,
  th {
    text-align: center;
    padding: 0 25px;

    ${media.phone`padding: 0`}
  }

  ${media.phone`
    padding: 0;
  `}
`;

export const SelectorInvasive = styled(Card)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  min-height:40px
  height: 40px;
  overflow: hidden;
  div {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 20%;
    text-align: center;
    justify-content: center;
    height: 40px;
  }
  ${media.phone`
    font-size: 12px;
  `}

  .active {
    border: 4px solid black;
  }
  div:first-child {
    background: ${invasiveColors["nonSurveillance"]};
  }
  div:nth-child(2) {
    background: ${invasiveColors["surveillance"]};
  }
  div:nth-child(3) {
    flex-grow: 2;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    label {
      margin: auto;
    }
    select {
      max-width: 150px;
      margin: auto;
      flex-grow: 2;
    }
  }
`;

export const SliderContainer = styled(Card)`
  align-items: center;
  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  height: 50px;
  justify-content: space-around;
  margin: 20px 0 20px 0;
  min-height: 50px;
  width: 100%;
`;

export const Tooltip = styled.div`
  height: auto;
  width: auto;
  background: black;
  color: white;
  opacity: 0.7;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
`;

export const TutorialCard = styled(Card)`
  height: auto;
  min-height: 400px;
  background: white;
  color: ${colors["black"]};
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 15px;

  ${media.phone`font-size: 14px;`}
  .tutorial-main-title,
  .tutorial-sub-title,
  .tutorial-list-header,
  h5,
  p,
  ul {
    margin-left: 10px;
    margin-right: 20px;
    list-style: none;
  }

  .tutorial-main-title {
    font-size: 2em;
    font-weight: bold;
    margin: 0.67em;
    margin-left: 10px;
  }

  .tutorial-sub-title {
    font-weight: bold;
    margin: 1.33em;
    margin-left: 10px;
  }

  .tutorial-list-header {
    font-weight: bold;
    margin: 1.33em;
  }

  li {
    line-height: 1em;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 9px 0 9px 0;
    div {
      margin-right: 10px;
    }
  }
  li:before {
    content: "";
    margin-right: 4px;
  }
  .tutorial-title-container {
    background: ${colors["vectorsurv-blue"]};
    clip-path: polygon(0 0, 100% 0, 100% 65%, 0% 100%);
    color: white;
    display: flex;
    justify-content: space-between;
  }
`;

export const VectorSurvTitle = styled.li`
  font-size: 24px;
  font-weight: bold;
  margin-right: 20px;
  ${media.phone`
    font-size: 18px;
    margin-left: -25px;
  `}
`;

export const Visualizations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 90vh;
  overflow: auto;
  width: 49%;
  max-width: 90%;
  margin-top: 10px;

  ${media.tablet`
    margin: 20px auto;
    width: 90%;
  `}
`;

export const ZikaMapLegend = styled.div`
  display: flex;
  box-sizing: border-box;
  div {
    display: flex;
    margin: 0 auto;
    width: 25%;
    text-align: center;
    padding: 5px;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: white;
    height: 40px;
  }
  div:first-child {
    background: ${colors["vectorsurv-navy"]};
  }
  div:nth-child(2) {
    background: ${colors["light-blue"]};
  }
  div:nth-child(3) {
    background: ${colors["light-red"]};
  }
  div:nth-child(4) {
    background: ${colors["red"]};
  }
`;
