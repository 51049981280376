import { timeFormat } from "d3-time-format";
import axios from "axios";

export const formatDate = timeFormat("%b %d, %Y");

export const formatDateWithoutYear = timeFormat("%b %d");

export const riskSurfaceTypes = [
  "Risk",
  "Environment",
  "Abundance",
  "Infection",
  "Seroconversion",
  "Dead Bird",
];

export const speciesAgnosticRiskSurfaceTypes = {
  Environment: true,
  "Dead Bird": true,
  Seroconversion: true,
};

export const riskSurfaceTypeAbbreviations = {
  "Overall Risk": "Risk",
  Environment: "Env",
  Abundance: "Abund",
  Infection: "Inf",
  Seroconversion: "Sero",
  "Dead Bird": "Bird",
};

export const formatRiskSurfaceType = (riskSurfaceType) => {
  if (riskSurfaceType === "Risk") {
    return "Overall Risk";
  }
  if (riskSurfaceType === "Overall Risk") {
    return "Risk";
  }
  if (!riskSurfaceTypes.includes(riskSurfaceType)) {
    return "An invalid risk surface type was entered";
  }
  return riskSurfaceType;
};

export const calculateRiskColor = (riskBins, riskValue) => {
  let index = 0;
  let riskColor = "gray";
  let found = false;

  while (index < riskBins.length && !found) {
    let item = riskBins[index];

    const { start, end, color } = item;

    riskColor = color;

    if (start <= riskValue && riskValue <= end) {
      found = true;
    }

    index += 1;
  }

  return riskColor;
};

const riskLegendTranslationsByState = {
  CA: {
    Normal: "Normal Season",
    Emergency: "Emergency Planning",
  },
};

export const generateRiskLegend = (riskBins, colorState) => {
  const translateTitles = riskLegendTranslationsByState[colorState] || {};
  const legendItems = [];
  for (let i = 0; i < riskBins.length; i += 1) {
    const { name, color } = riskBins[i];
    const newLegendItem = {
      strokeStyle: "solid",
      strokeWidth: 15,
      color,
      title: translateTitles[name] || name || `${i + 1} / ${riskBins.length}`,
    };
    legendItems.push(newLegendItem);
  }

  return legendItems;
};

export const formatDateForQuery = (year, month, day) => {
  let dateString = "";
  dateString += year;
  dateString += "-";
  dateString += month < 10 ? "0" + month : month;
  dateString += "-";
  dateString += day < 10 ? "0" + day : day;
  return dateString;
};

export const getInitialSaturday = (year) => {
  let firstSaturdayDate = 1;
  let count = 0;
  let targetDayValue = 6;
  while (
    count < 8 &&
    new Date(`${year}/01/${firstSaturdayDate}`).getDay() !== targetDayValue
  ) {
    firstSaturdayDate += 1;
    count += 1; // for emergencies
  }
  return firstSaturdayDate;
};

// be able to translate week number to the date it represents
export const numToWeek = (num, year, initialSaturday) => {
  const d = new Date(`${year}/01/${initialSaturday} 12:00:00 GMT-07:00`);
  d.setDate(d.getDate() + +num * 7, 1);
  return d;
};

// Only used if no week provided. Give the current week of the current year.
export const getCurrentWeek = () => {
  const year = new Date().getFullYear();
  const initialSaturday = getInitialSaturday(year);
  let d = new Date(`${year}/01/${initialSaturday} 12:00:00 GMT-07:00`);
  let countedWeeks = 0;
  const maxWeeksTotal = 54;
  while (d < Date.now() && countedWeeks <= maxWeeksTotal) {
    countedWeeks += 1;
    d.setDate(d.getDate() + 7, 1);
  }
  return countedWeeks;
};

// Returns the number of weeks in a given year, where a week begins on Saturday
export const getNumberOfWeeksInYear = (year, initialSaturday) => {
  let d = new Date(`${year}/01/${initialSaturday} 12:00:00 GMT-07:00`);
  let totalWeeks = 0;

  while (year === d.getFullYear()) {
    totalWeeks += 1;
    d.setDate(d.getDate() + 7, 1);
  }

  return totalWeeks;
};

// Gets the risk component values for a point. If the year's series has been fetched, should go to that, but not done yet.
export const getPointValues = async (
  authQueryString,
  longitude,
  latitude,
  year,
  initialSaturday,
  species,
  week
) => {
  const initialDate = numToWeek(week, year, initialSaturday);
  const stringDate = formatDateForQuery(
    initialDate.getFullYear(),
    initialDate.getMonth() + 1,
    initialDate.getDate()
  );

  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePointValues/${longitude}/${latitude}/${species}/${stringDate}/${authQueryString}`
      `https://mathew.vectorsurv.org/v2/surfaces/livePointValues/${longitude}/${latitude}/${species}/${stringDate}/${authQueryString}`
    );

    const data = response.data;

    let count = 0; // count how many factors there were, and only average risk if there are more than 1

    const formattedData = {};
    let numerator = 0;
    let denominator = 0;
    riskSurfaceTypes.forEach((riskSurfaceType) => {
      if (riskSurfaceType === "Risk") {
        // If risk should be the average, don't add it here, calculate it later
        return;
      }
      if (data[riskSurfaceType]) {
        const formattedValue =
          +data[riskSurfaceType].toFixed(1) === data[riskSurfaceType]
            ? data[riskSurfaceType]
            : +data[riskSurfaceType].toFixed(1);
        formattedData[riskSurfaceType] = formattedValue;
        numerator += formattedValue;
        denominator += 1;
        count += 1;
      } else {
        formattedData[riskSurfaceType] = 0;
      }
    });

    formattedData["Risk"] =
      (data["Risk"] || count > 1) && numerator
        ? +(numerator / denominator).toFixed(1) === numerator / denominator
          ? numerator / denominator
          : (numerator / denominator).toFixed(1)
        : 0;
    return formattedData;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// Gets the series for a point for the year for the specified risk component. This would be more efficient when download is not an option.
export const getPointSeries = async (
  authQueryString,
  longitude,
  latitude,
  year,
  initialSaturday,
  species,
  week,
  riskSurfaceType
) => {
  const initialDate = numToWeek(week, year, initialSaturday);
  const stringDate = formatDateForQuery(
    initialDate.getFullYear(),
    initialDate.getMonth() + 1,
    initialDate.getDate()
  );

  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePointSeries/${longitude}/${latitude}/${species}/${stringDate}/${riskSurfaceType}${authQueryString}`
      `https://mathew.vectorsurv.org/v2/surfaces/livePointSeries/${longitude}/${latitude}/${species}/${stringDate}/${riskSurfaceType}${authQueryString}`
    );
    const data = response.data;

    return data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// the '2' funcs get the entire years worth of data for all risk types, which is used for the downloads. The regulars should be used for mobile at some point, or when the user opts out of downloads.
export const getPointSeries2 = async (
  authQueryString,
  longitude,
  latitude,
  year
) => {
  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePointSeriesAll/${longitude}/${latitude}/${year}/${authQueryString}`//testSeries${authQueryString} // second one gets the cached series
      `https://mathew.vectorsurv.org/v2/surfaces/livePointSeriesAll/${longitude}/${latitude}/${year}/${authQueryString}` //testSeries${authQueryString} // second one gets the cached series
    );

    const data = response.data;

    return data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// Gets the risk component values for a polygon. If the year's series has been fetched, should go to that, but not done yet.
export const getPolygonValues = async (
  authQueryString,
  geoJSON,
  year,
  initialSaturday,
  species,
  week
) => {
  const initialDate = numToWeek(week, year, initialSaturday);
  const stringDate = formatDateForQuery(
    initialDate.getFullYear(),
    initialDate.getMonth() + 1,
    initialDate.getDate()
  );
  const stringoJSON = JSON.stringify(geoJSON);

  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePolygonValues/${stringoJSON}/${species}/${stringDate}/${authQueryString}`
      `https://mathew.vectorsurv.org/v2/surfaces/livePolygonValues/${stringoJSON}/${species}/${stringDate}/${authQueryString}`
    );

    const data = response.data;

    let count = 0; // count how many factors there were, and only average risk if there are more than 1

    const formattedData = {};
    let numerator = 0;
    let denominator = 0;
    riskSurfaceTypes.forEach((riskSurfaceType) => {
      if (riskSurfaceType === "Risk") {
        // If risk should be the average, don't add it here, calculate it later
        return;
      }
      if (data[riskSurfaceType]) {
        const formattedValue =
          +data[riskSurfaceType].toFixed(1) === data[riskSurfaceType]
            ? data[riskSurfaceType]
            : +data[riskSurfaceType].toFixed(1);
        formattedData[riskSurfaceType] = formattedValue;
        numerator += formattedValue;
        denominator += 1;
        count += 1;
      } else {
        formattedData[riskSurfaceType] = 0;
      }
    });

    formattedData["Risk"] =
      (data["Risk"] || count > 1) && numerator
        ? +(numerator / denominator).toFixed(1) === numerator / denominator
          ? numerator / denominator
          : (numerator / denominator).toFixed(1)
        : 0;
    return formattedData;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// Gets the series for a polygon for the year for the specified risk component. This would be more efficient when download is not an option.
export const getPolygonSeries = async (
  authQueryString,
  geoJSON,
  year,
  initialSaturday,
  species,
  week,
  riskSurfaceType
) => {
  const initialDate = numToWeek(week, year, initialSaturday);
  const stringDate = formatDateForQuery(
    initialDate.getFullYear(),
    initialDate.getMonth() + 1,
    initialDate.getDate()
  );

  const stringoJSON = JSON.stringify(geoJSON);

  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePolygonSeries/${stringoJSON}/${species}/${stringDate}/${riskSurfaceType}${authQueryString}`
      `https://mathew.vectorsurv.org/v2/surfaces/livePolygonSeries/${stringoJSON}/${species}/${stringDate}/${riskSurfaceType}${authQueryString}`
    );
    const data = response.data;

    return data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// the '2' funcs get the entire years worth of data for all risk types, which is used for the downloads. The regulars should be used for mobile at some point, or when the user opts out of downloads.
export const getPolygonSeries2 = async (authQueryString, geoJSON, year) => {
  const stringoJSON = JSON.stringify(geoJSON);

  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePolygonSeriesAll/${stringoJSON}/${year}${authQueryString}` //testSeries${authQueryString} // second one gets the cached series
      `https://mathew.vectorsurv.org/v2/surfaces/livePolygonSeriesAll/${stringoJSON}/${year}${authQueryString}` //testSeries${authQueryString} // second one gets the cached series
    );
    const data = response.data;

    return data;
  } catch (err) {
    console.error(err);
    return false;
  }
};
